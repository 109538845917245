<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                        <data-table
                            ref="tablaSalarioMinimo"
                            :tableName="'Salarios mínimos'"
                            :url="url"
                            :columns="columns"
                            :filters="filters"
                            @setFilters="setFilters"
                            :perPage="[10, 25, 50, 100]"
                        >
                            <template slot="actionButtonsLeft">
                                <div class="botonAgregar">
                                    <v-btn
                                        rounded
                                        color="#004BAF"
                                        class="btnAdd"
                                        right
                                        @click="abrirModal('add')"
                                    >
                                        <v-icon dark class="mr-2">add</v-icon>Agregar
                                        <div class="mr-4"></div>
                                    </v-btn>
                                </div>
                            </template>
                            <template slot="filters">
                                <v-col xs="12" sm="12" md="12">
                                    <div class="RangoIngreso">
                                        <span >Rango de fechas</span>
                                    </div>
                                </v-col>

                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="fecha_inicio"
                                        label="Fecha inicio"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaInicio"
                                    />
                                </v-col>

                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="fecha_final"
                                        label="Fecha final"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaFinal"
                                    />
                                </v-col>
                            </template>

                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td><div>{{dateFormat(item.fecha)}}</div></td>
                                    <td><div>{{item.descripcion}}</div></td>
                                    <td><div class="tblCenter">{{item.minimo_A}}</div></td>
                                    <td><div class="tblCenter">{{item.minimo_B}}</div></td>
                                    <td><div class="tblCenter">{{item.minimo_C}}</div></td>
                                    <td>
                                        <div class="tblOpciones">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            rules="required"
                                            v-model="salarioMinimo.fecha"
                                            label="Fecha"
                                            placeholder="dd/mm/aaaa"
                                            ref="Fecha"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                            <v-text-field 
                                                label="Descripción" 
                                                outlined 
                                                v-model="salarioMinimo.descripcion" 
                                                :error-messages="errors" 
                                                required
                                            > 
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Mínimo A"
                                            prefix="$"
                                            v-model="salarioMinimo.minimo_A"
                                            v-bind:options="options"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Mínimo B"
                                            prefix="$"
                                            v-model="salarioMinimo.minimo_B"
                                            v-bind:options="options"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Mínimo C"
                                            prefix="$"
                                            v-model="salarioMinimo.minimo_C"
                                            v-bind:options="options"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Tope EG"
                                            prefix="$"
                                            v-model="salarioMinimo.tope_eg"
                                            v-bind:options="options"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Tope IVCM"
                                            prefix="$"
                                            v-model="salarioMinimo.tope_ivcm"
                                            v-bind:options="options"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="3" class="py-0" >
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Tope RT"
                                            prefix="$"
                                            v-model="salarioMinimo.tope_rt"
                                            v-bind:options="options"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                       <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Tope Info"
                                            prefix="$"
                                            v-model="salarioMinimo.tope_info"
                                            v-bind:options="options"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="UMA" rules="required|numeros_positivos">
                                            <v-text-field 
                                                outlined 
                                                label="UMA" 
                                                v-model="salarioMinimo.uma" 
                                                :error-messages="errors"
                                                oninput=" this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="UMI" rules="required|numeros_positivos">
                                            <v-text-field 
                                                outlined label="UMI" 
                                                v-model="salarioMinimo.umi" 
                                                :error-messages="errors" 
                                                oninput="this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import apiSalarioMinimo from "../../api/nominas/salarioMinimo";
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

export default {
    components: {
        VuetifyDatePicker,
        VuetifyMoneyValidate,
        'data-table': Datatable,
    },
    data() {
        return {
            url         : "salario-minimo/find",
            columns     : [
                {
                    label: 'Fecha',
                    name: 'fecha',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Mínimo A',
                    name: 'minimo_A',
                    align: 'center',
                    filterable: true
                },
                {
                    label: 'Mínimo B',
                    name: 'minimo_B',
                    align: 'center',
                    filterable: true
                },
                {
                    label: 'Mínimo C',
                    name: 'minimo_C',
                    align: 'center',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
            },
            userData    : this.$session.get('usuario'),
            accion      : null,
            tituloModal : null,
            imagen      : "",
            dialog      : false,
            isLoading   : false,
            isSaving    : false,
            
            salarioMinimo   : {
                fecha       : '',
                descripcion : '',
                minimo_A    : null,
                minimo_B    : null,
                minimo_C    : null,
                tope_eg     : null,
                tope_ivcm   : null,
                tope_rt     : null,
                tope_info   : null,
                uma         : null,
                umi         : null,
            },
            fecha_inicio    : null,
            fecha_final     : null,
            menu_inicio     : null,
            menu_final      : null,
            showFechaInicio : false,
             options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
        }
    },
    methods: {
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;
            this.imagen = "/static/modal/catalogo.svg";

            if(accion == "add") {
                this.tituloModal    = "Registro Salario Mínimo";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal                = "Actualizar Salario Mínimo";
                this.imagen                     = "/static/modal/catalogoSATupdate.svg";
                this.salarioMinimo.id           = data.id;
                this.salarioMinimo.fecha        = data.fecha;
                this.salarioMinimo.descripcion  = data.descripcion;
                this.salarioMinimo.minimo_A     = data.minimo_A;
                this.salarioMinimo.minimo_B     = data.minimo_B;
                this.salarioMinimo.minimo_C     = data.minimo_C;
                this.salarioMinimo.tope_eg      = data.tope_eg;
                this.salarioMinimo.tope_ivcm    = data.tope_ivcm;
                this.salarioMinimo.tope_rt      = data.tope_rt;
                this.salarioMinimo.tope_info    = data.tope_info;
                this.salarioMinimo.uma          = data.uma;
                this.salarioMinimo.umi          = data.umi;

                setTimeout(()=>this.$refs.Fecha.setDate(data.fecha),200);
            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.salarioMinimo.id           = null;
            this.salarioMinimo.fecha        = '';
            this.salarioMinimo.descripcion  = '';
            this.salarioMinimo.minimo_A     = null;
            this.salarioMinimo.minimo_B     = null;
            this.salarioMinimo.minimo_C     = null;
            this.salarioMinimo.tope_eg      = null;
            this.salarioMinimo.tope_ivcm    = null;
            this.salarioMinimo.tope_rt      = null;
            this.salarioMinimo.tope_info    = null;
            this.salarioMinimo.uma          = null;
            this.salarioMinimo.umi          = null;

            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            if(this.$refs.Fecha != undefined || this.$refs.Fecha != null){
                this.$refs.Fecha.resetDate();
            }

            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
         
        setFilters() {
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let dates = 0;

            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                dates = dates+1;
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                dates = dates+1;
            }

            let filterParams = {
                min_fecha: fecha_inicio,
                max_fecha: fecha_final,
            };

            let defaultParams = {
                activo: true,
                paginate: true,
                include_padres: true,
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;
            this.resetValues();
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.accion === "add") {
                    apiSalarioMinimo.store(this.salarioMinimo)
                    .then((response) => {
                        this.$refs.tablaSalarioMinimo.getData();
                        Notify.Success("Operación exitosa", "El registro salario mínimo se guardó satisfactoriamente.");
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiSalarioMinimo.update(this.salarioMinimo)
                    .then((response) => {
                        this.$refs.tablaSalarioMinimo.getData();
                        Notify.Success("Operación exitosa", "El registro de salario mínimo se actualizó satisfactoriamente.");
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este salario mínimo?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiSalarioMinimo.delete(data.id).then(response => {
                        this.$refs.tablaSalarioMinimo.getData();
                        Notify.Success("Tipo de baja eliminado", "El tipo de baja ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>

