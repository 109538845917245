import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("salario-minimo/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("salario-minimo/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("salario-minimo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("salario-minimo/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("salario-minimo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}