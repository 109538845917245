<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                    <v-col cols="12" lg="12">
                        <data-table
                            ref="tablaTipoBaja"
                            :tableName="'Bajas IMSS'"
                            :url="url"
                            :columns="columns"
                            :filters="filters"
                            :showAdvancedFilters="false"
                            :perPage="[10,25,50,100]"
                        >
                            <template slot="actionButtonsLeft">
                                <div class="botonAgregar">
                                    <v-btn 
                                        color="#004BAF" 
                                        class="btnAdd" 
                                        right
                                        rounded 
                                        @click="abrirModal('add')"
                                    >
                                        <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                    </v-btn>
                                </div>
                            </template>
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td><div>{{item.clave}}</div></td>
                                    <td><div>{{item.descripcion}}</div></td>
                                    <td>
                                        <div class="tblOpciones">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </data-table>
                    </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="700px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" lg="4" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                            <v-text-field 
                                                outlined 
                                                label="Clave" 
                                                v-model="tipoBaja.clave" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" lg="8" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                            <v-text-field 
                                                outlined 
                                                label="Descripción" 
                                                v-model="tipoBaja.descripcion" 
                                                :error-messages="errors" 
                                                required
                                            >late>
                                                -->
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" lg="4" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Origen" rules="required">
                                            <v-text-field 
                                                outlined 
                                                label="Origen" 
                                                v-model="tipoBaja.origen" 
                                                :error-messages="errors" 
                                                required
                                            >    
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" lg="4" class="py-0">
                                        <v-switch v-model="tipoBaja.recontratable" class="ma-4" label="Recontratable"></v-switch>
                                    </v-col>

                                    <v-col cols="12" lg="4" class="py-0">
                                        <v-text-field 
                                            outlined 
                                            label="Baja IMSS" 
                                            v-model="tipoBaja.baja_imms"
                                        >    
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import apiTipoBajaImss from "../../api/nominas/tipoBajaImss";
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            url         : "catalogo/TipoBajaImss/find",
            columns     : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
            },
            tipoBaja    : {
                id              : null,
                clave           : '',
                descripcion     : '',
                origen          : '',
                recontratable   : false,
                baja_imms       : '',
            },
            userData    : this.$session.get('usuario'),
            accion      : null,
            tituloModal : null,
            imagen      : "",
            dialog      : false,
            isLoading   : false,
            isSaving    : false,
        }
    },
    methods: {
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;
            this.imagen = "/static/modal/catalogo.svg";

            if(accion == "add") {
                this.tituloModal    = "Registro tipo de baja";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal            = "Actualizar tipo de baja";
                this.imagen                 = "/static/modal/catalogoSATupdate.svg";
                this.tipoBaja.id            = data.id;
                this.tipoBaja.clave         = data.clave;
                this.tipoBaja.descripcion   = data.descripcion;
                this.tipoBaja.origen        = data.origen;
                this.tipoBaja.recontratable = data.recontratable;
                this.tipoBaja.baja_imms     = data.baja_imms;
            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.tipoBaja.id            = null;
            this.tipoBaja.clave         = '';
            this.tipoBaja.descripcion   = '';
            this.tipoBaja.origen        = '';
            this.tipoBaja.recontratable = false;
            this.tipoBaja.baja_imms     = '';
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }

            if(this.accion === "add") {
                apiTipoBajaImss.store(this.tipoBaja)
                .then((response) => {
                    this.$refs.tablaTipoBaja.getData();
                    Notify.Success("Operación exitosa", "El registro tipo de baja se guardó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiTipoBajaImss.update(this.tipoBaja)
                .then((response) => {
                    this.$refs.tablaTipoBaja.getData();
                    Notify.Success("Operación exitosa", "El registro de tipo de baja se actualizó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este tipo de baja IMSS?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiTipoBajaImss.delete(data.id).then(response => {
                        this.$refs.tablaTipoBaja.getData();
                        Notify.Success("Tipo de baja eliminado", "El tipo de baja ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>

